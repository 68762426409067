import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "Privacy Page",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Privacy() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="relative max-w-lg lg:max-w-8xl mx-auto">
              <div className="absolute top-0 left-0 -ml-64 -mt-80 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="absolute bottom-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
              <div className="relative">
                <div className="max-w-2xl mx-auto mb-18 text-center">
                  <span className="block text-lg text-gray-500 tracking-tight mb-6">
                    Effective Date: 18-01-24
                  </span>
                  <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight">
                    Privacy Policy of Bromo.ai
                  </h2>
                </div>
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full px-4 mb-16 lg:mb-0">
                    <a className="block" href="/todo">
                      <div className="w-full">
                        <span className="block text-base tracking-tight text-gray-200 opacity-70 mb-5">
                          Created - Dec 20, 2022
                        </span>
                        {/* <h3 className="text-3xl font-medium text-white mb-5">
                          Consectures Dummy Content Velit officia consequat duis
                          enim velit
                        </h3> */}
                        <p className="text-lg tracking-tight text-gray-100 opacity-60">
                          <p>
                            <strong>Privacy Policy of Bromo.ai</strong>
                          </p>
                          <p>
                            <strong>Introduction</strong>
                          </p>
                          <p>
                            Welcome to Bromo.ai! We are committed to protecting
                            your privacy and ensuring that your personal
                            information is handled responsibly and
                            transparently. This Privacy Policy applies to all
                            personal data processed by us when you use our
                            AI-based services through our website, accessible
                            via bromo.ai.
                          </p>
                          <p>
                            <strong>Scope and Consent</strong>
                          </p>
                          <p>
                            By using Bromo.ai and its services, you consent to
                            the collection, use, disclosure, and protection of
                            your personal data as outlined in this policy. Our
                            services are not intended for individuals under the
                            age of 18.
                          </p>
                          <p>
                            <strong>Information Collection and Use</strong>
                          </p>
                          <ol>
                            <li>
                              <p>
                                <strong>Account Information</strong>: When you
                                create an account with Bromo.ai, we collect your
                                email address and other relevant details to
                                provide you with our services.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Usage Data</strong>: We collect
                                information on how you interact with our
                                services, including the AI models you use, your
                                preferences, and the projects you work on.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>Technical Data</strong>: This includes
                                IP address, browser type, operating system, and
                                device information used to access our services.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>
                                  Cookies and Tracking Technologies
                                </strong>
                                : We use cookies and similar technologies to
                                enhance your experience, personalize content,
                                and analyze our website traffic.
                              </p>
                            </li>
                            <li>
                              <p>
                                <strong>AI Interaction Data</strong>: Data
                                generated when you interact with our AI models,
                                including inputs, outputs, and model
                                preferences.
                              </p>
                            </li>
                          </ol>
                          <p>
                            <strong>Purpose of Data Collection</strong>
                          </p>
                          <p>We use your data to:</p>
                          <ul>
                            <li>
                              Provide, maintain, and improve our services.
                            </li>
                            <li>Personalize your experience.</li>
                            <li>Communicate with you about our services.</li>
                            <li>
                              Ensure the security and integrity of our services.
                            </li>
                            <li>Comply with legal obligations.</li>
                          </ul>
                          <p>
                            <strong>Data Sharing and Disclosure</strong>
                          </p>
                          <p>We may share your data:</p>
                          <ul>
                            <li>
                              With service providers who perform services on our
                              behalf.
                            </li>
                            <li>
                              If required by law or in response to legal
                              requests.
                            </li>
                            <li>
                              In connection with a merger, sale, or asset
                              transfer.
                            </li>
                          </ul>
                          <p>
                            <strong>Data Retention</strong>
                          </p>
                          <p>
                            We retain personal data for as long as necessary to
                            provide our services, comply with legal obligations,
                            resolve disputes, and enforce our agreements.
                          </p>
                          <p>
                            <strong>Data Security</strong>
                          </p>
                          <p>
                            We implement robust security measures to protect
                            your data from unauthorized access, disclosure,
                            alteration, and destruction.
                          </p>
                          <p>
                            <strong>Your Rights</strong>
                          </p>
                          <p>Under California law, you have the right to:</p>
                          <ul>
                            <li>
                              Know what personal data we collect and how we use
                              it.
                            </li>
                            <li>Request deletion of your personal data.</li>
                            <li>Opt-out of the sale of personal data.</li>
                            <li>
                              Non-discrimination for exercising your privacy
                              rights.
                            </li>
                          </ul>
                          <p>
                            <strong>International Transfers</strong>
                          </p>
                          <p>
                            Your information may be transferred to, stored, and
                            processed in countries other than your own. We
                            ensure that appropriate safeguards are in place for
                            such transfers.
                          </p>
                          <p>
                            <strong>Changes to This Policy</strong>
                          </p>
                          <p>
                            We may update this Privacy Policy from time to time.
                            We will notify you of any changes by posting the new
                            policy on this page.
                          </p>
                          <p>
                            <strong>Contact Us</strong>
                          </p>
                          <p>
                            If you have any questions or concerns about our
                            Privacy Policy or data practices, please contact us
                            at [Insert Contact Information].
                          </p>
                          <p>
                            <em>
                              This Privacy Policy is subject to the laws of
                              California, United States, and is intended to
                              comply with the California Consumer Privacy Act
                              (CCPA) and other relevant regulations.
                            </em>
                          </p>
                          <hr />
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
