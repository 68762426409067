/* eslint-disable react/prop-types */
import { createContext, useState, useEffect } from "react";
import { fetchDataFromApi } from "../utils/Api";

export let Context: any;

export const AppContext = (props: any) => {
  const [loading, setLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [selectcategories, setSelectCategories] = useState("New");
  const [mobileMenu, setMobileMenu] = useState(false);
  Context = createContext({
    loading: loading || false,
    setLoading,
    searchResult: searchResult || [],
    selectcategories,
    setSelectCategories,
    mobileMenu,
    setMobileMenu,
  });

  useEffect(() => {
    fetchSelectedCategoryData(selectcategories);
  }, [selectcategories]);

  const fetchSelectedCategoryData = (query: string) => {
    setLoading(true);
    fetchDataFromApi(`search/?q=${query}`)
      .then(({ contents }) => {
        setSearchResult(contents);
        setLoading(false);
      })
      .catch((e) => console.log(e));
  };

  return (
    <Context.Provider
      value={{
        loading: loading || false,
        setLoading,
        searchResult: searchResult || [],
        selectcategories,
        setSelectCategories,
        mobileMenu,
        setMobileMenu,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

//  Context;
