// React hooks necessary for the functionality of this component are imported
import React from "react";
// The ChakraProvider is used for consistent UI design across the app, and the 'theme' allows for custom theming
import { ChakraProvider, theme } from "@chakra-ui/react";
// Importing routing components from 'react-router-dom' for managing the app's navigation
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
// import Feed from "./components/lib/video/Feed";
// import SearchResult from "./components/lib/video/SearchResult";
// import VideoDetail from "./components/lib/video/VideoDetail";
import { AppContext } from "./components/misc/ContextApi";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Blog from "./pages/Blog";
import Privacy from "./pages/Privacy";
import "./Tailwind.css";

/**
 * 'App' is a functional component that structures and navigates the application.
 * It uses react-router-dom for routing and wraps the entire application with ChakraProvider for consistent UI design.
 * The 'createBrowserRouter' function is used to create a browser router with the application's specific route configuration.
 * It accepts an array of objects where each object represents a route configuration.
 * Each route object has three properties: 'path', 'element', and 'errorElement'. These properties define the URL path, the component to render for that URL path, and the component to render when an error occurs respectively.
 * The routes defined include '/' which renders the Home component, and '/video/:videoId' which renders the Editor component.
 * The entire application is wrapped in a RouterProvider, which is further wrapped by ChakraProvider for UI uniformity.
 **/
const App: React.FC = () => {
  // A browser router is created using 'createBrowserRouter', with the specific route configuration for this application
  const router = createBrowserRouter([
    {
      // Home route configuration
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },

    {
      // Home route configuration
      path: "/login",
      element: <Login />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/signup",
      element: <Signup />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/blog",
      element: <Blog />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/privacy",
      element: <Privacy />,
      errorElement: <ErrorPage />,
    },
    {
      // Home route configuration
      path: "/terms",
      element: <Privacy />,
      errorElement: <ErrorPage />,
    },
  ]);

  /**
   * The return statement for the 'App' component.
   * It returns a JSX element which has the ChakraProvider wrapping a RouterProvider.
   * The RouterProvider is passed the 'router' object as a prop which contains the configured routes.
   * All the application components are children of these providers.
   **/
  return (
    // The ChakraProvider is used to provide a uniform UI design across the application
    <AppContext>
      <ChakraProvider theme={theme}>
        <RouterProvider router={router} />
      </ChakraProvider>
    </AppContext>
  );
};

// The App functional component is exported as the default for use in other parts of the application
export default App;
