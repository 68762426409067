import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

const meta = {
  title: "",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className="relative bg-body overflow-hidden">
          <nav className="relative z-10 py-7">
            <div className="container mx-auto px-4">
              <div className="relative flex items-center justify-between">
                <a className="inline-block" href="/">
                  <img
                    className="h-10"
                    src="homepage/images/logo-white.svg"
                    alt=""
                  />
                </a>
                <div className="flex items-center justify-end">
                  {/* <div className="hidden lg:block mr-10">
                    <a
                      className="inline-flex py-2 px-4 mr-4 items-center justify-center text-sm font-medium uppercase text-white hover:text-violet-500"
                      href="/login"
                    >
                      SIGN IN
                    </a>
                    <a
                      className="inline-flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full"
                      href="/signup"
                    >
                      SIGN UP
                    </a>
                  </div> */}
                  <button className="navbar-burger text-white hover:text-violet-500">
                    <svg
                      width={32}
                      height={32}
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 16H29"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3 8H29"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20 24L29 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </nav>
          <div className="relative pt-24 lg:pt-44 pb-40 lg:pb-72">
            <div className="relative z-10 container mx-auto px-4">
              <div className="flex flex-wrap -mx-4 items-center">
                <div className="w-full lg:w-1/2 xl:w-3/5 px-4 mb-32 lg:mb-0">
                  <div className="max-w-md mx-auto lg:max-w-none">
                    <h1 className="font-heading text-4xl sm:text-6xl md:text-7xl xl:text-8xl text-white font-semibold leading-none mb-8">
                      INTRODUCING BROMO.IO
                    </h1>
                    <p className="text-2xl text-gray-400 mb-8">
                      <span className="block">Welcome to Bromo -</span>
                      <span className="block">
                        Your Intelligent AI Video Editor!
                      </span>
                    </p>
                    <a
                      className="group inline-flex h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full"
                      href="https://calendly.com/bhanuc/15min"
                    >
                      <span className="mr-2">Join the Closed Beta</span>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 xl:w-2/5 px-4">
                  <div className="xl:inline-block relative">
                    <img
                      className="absolute top-0 right-0 -mt-8 mr-12 animate-spinStar"
                      src="homepage/bromo-assets/headers/blink-sm.png"
                      alt=""
                    />
                    <img
                      className="absolute bottom-0 left-0 lg:-ml-24 xl:-ml-40 animate-spinStar"
                      src="homepage/bromo-assets/headers/blink-md.png"
                      alt=""
                    />
                    <img
                      className="block px-10 md:px-0 mx-auto lg:mr-0"
                      src="homepage/bromo-assets/headers/oval-robot-photo.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <img
              className="absolute bottom-0 left-0 w-full"
              src="homepage/bromo-assets/headers/bg-bottom-lines.png"
              alt=""
            />
          </div>
          <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-md z-50">
            <div className="navbar-backdrop fixed inset-0 bg-violet-900 opacity-20" />
            <nav className="relative flex flex-col py-7 px-10 w-full h-full bg-white overflow-y-auto">
              <div className="flex mb-auto items-center">
                <a className="inline-block mr-auto" href="/">
                  <img className="h-10" src="homepage/images/logo.svg" alt="" />
                </a>
                <a className="navbar-close" href="/">
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 18L18 6M6 6L18 18"
                      stroke="#111827"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
              </div>
              <div className="py-12 mb-auto">
                <ul className="flex-col">
                  <li className="mb-6">
                    <a
                      className="inline-block text-base text-black font-medium uppercase"
                      href="/"
                    >
                      FEATURED
                    </a>
                  </li>
                  <li className="mb-6">
                    <a
                      className="inline-block text-base text-black font-medium uppercase"
                      href="/"
                    >
                      SOLUTIONS
                    </a>
                  </li>
                  <li className="mb-6">
                    <a
                      className="inline-block text-base text-black font-medium uppercase"
                      href="/"
                    >
                      PRODUCTS
                    </a>
                  </li>
                  <li>
                    <a
                      className="inline-block text-base text-black font-medium uppercase"
                      href="/"
                    >
                      ARTICLES
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <a
                  className="flex py-2 px-4 mb-4 items-center justify-center text-sm font-medium uppercase text-violet-900 hover:text-violet-500"
                  href="/login"
                >
                  SIGN IN
                </a>
                <a
                  className="flex h-11 py-2 px-4 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full"
                  href="/signup"
                >
                  SIGN UP
                </a>
              </div>
            </nav>
          </div>
        </section>
        <section className="relative py-12 sm:pt-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="flex flex-wrap items-end -mx-4">
              <div className="w-full lg:w-1/2 px-4 mb-20 lg:mb-0">
                <div className="max-w-lg mx-auto lg:mx-0 lg:max-w-none pb-32">
                  <span className="inline-flex items-center px-3.5 h-7 mb-8 text-xs font-medium text-white leading-6 bg-gradient-tag rounded-full">
                    FEATURES
                  </span>
                  <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-18">
                    Look no further! BROMO is here to revolutionize.
                  </h2>
                  <div className="flex mb-16 items-start">
                    <div className="mr-4">
                      <svg
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.8933 8.82667C23.758 8.50087 23.499 8.24197 23.1733 8.10667C23.013 8.03835 22.8408 8.00211 22.6666 8H9.33325C8.97963 8 8.64049 8.14048 8.39044 8.39052C8.14039 8.64057 7.99992 8.97971 7.99992 9.33333C7.99992 9.68696 8.14039 10.0261 8.39044 10.2761C8.64049 10.5262 8.97963 10.6667 9.33325 10.6667H19.4533L8.38659 21.72C8.26161 21.844 8.16242 21.9914 8.09473 22.1539C8.02704 22.3164 7.99219 22.4907 7.99219 22.6667C7.99219 22.8427 8.02704 23.017 8.09473 23.1794C8.16242 23.3419 8.26161 23.4894 8.38659 23.6133C8.51054 23.7383 8.658 23.8375 8.82048 23.9052C8.98296 23.9729 9.15724 24.0077 9.33325 24.0077C9.50927 24.0077 9.68354 23.9729 9.84602 23.9052C10.0085 23.8375 10.156 23.7383 10.2799 23.6133L21.3333 12.5467V22.6667C21.3333 23.0203 21.4737 23.3594 21.7238 23.6095C21.9738 23.8595 22.313 24 22.6666 24C23.0202 24 23.3593 23.8595 23.6094 23.6095C23.8594 23.3594 23.9999 23.0203 23.9999 22.6667V9.33333C23.9978 9.1591 23.9616 8.98696 23.8933 8.82667Z"
                          fill="#8A33E2"
                        />
                      </svg>
                    </div>
                    <div>
                      <h4 className="text-2xl text-white font-medium">
                        AI Models Integrations
                      </h4>
                      <p className="text-lg text-gray-600 tracking-tight">
                        Integrates with Stable Diffusion Video, Pika and other
                        leading AI models.
                      </p>
                    </div>
                  </div>
                  <div className="flex mb-16 items-start">
                    <div className="mr-4">
                      <svg
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.8933 8.82667C23.758 8.50087 23.499 8.24197 23.1733 8.10667C23.013 8.03835 22.8408 8.00211 22.6666 8H9.33325C8.97963 8 8.64049 8.14048 8.39044 8.39052C8.14039 8.64057 7.99992 8.97971 7.99992 9.33333C7.99992 9.68696 8.14039 10.0261 8.39044 10.2761C8.64049 10.5262 8.97963 10.6667 9.33325 10.6667H19.4533L8.38659 21.72C8.26161 21.844 8.16242 21.9914 8.09473 22.1539C8.02704 22.3164 7.99219 22.4907 7.99219 22.6667C7.99219 22.8427 8.02704 23.017 8.09473 23.1794C8.16242 23.3419 8.26161 23.4894 8.38659 23.6133C8.51054 23.7383 8.658 23.8375 8.82048 23.9052C8.98296 23.9729 9.15724 24.0077 9.33325 24.0077C9.50927 24.0077 9.68354 23.9729 9.84602 23.9052C10.0085 23.8375 10.156 23.7383 10.2799 23.6133L21.3333 12.5467V22.6667C21.3333 23.0203 21.4737 23.3594 21.7238 23.6095C21.9738 23.8595 22.313 24 22.6666 24C23.0202 24 23.3593 23.8595 23.6094 23.6095C23.8594 23.3594 23.9999 23.0203 23.9999 22.6667V9.33333C23.9978 9.1591 23.9616 8.98696 23.8933 8.82667Z"
                          fill="#1AC2E7"
                        />
                      </svg>
                    </div>
                    <div>
                      <h4 className="text-2xl text-white font-medium">
                        Personalized Experiences
                      </h4>
                      <p className="text-lg text-gray-600 tracking-tight">
                        Create Videos that adhere to the branding guidelines.
                      </p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <div className="mr-4">
                      <svg
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M23.8933 8.82667C23.758 8.50087 23.499 8.24197 23.1733 8.10667C23.013 8.03835 22.8408 8.00211 22.6666 8H9.33325C8.97963 8 8.64049 8.14048 8.39044 8.39052C8.14039 8.64057 7.99992 8.97971 7.99992 9.33333C7.99992 9.68696 8.14039 10.0261 8.39044 10.2761C8.64049 10.5262 8.97963 10.6667 9.33325 10.6667H19.4533L8.38659 21.72C8.26161 21.844 8.16242 21.9914 8.09473 22.1539C8.02704 22.3164 7.99219 22.4907 7.99219 22.6667C7.99219 22.8427 8.02704 23.017 8.09473 23.1794C8.16242 23.3419 8.26161 23.4894 8.38659 23.6133C8.51054 23.7383 8.658 23.8375 8.82048 23.9052C8.98296 23.9729 9.15724 24.0077 9.33325 24.0077C9.50927 24.0077 9.68354 23.9729 9.84602 23.9052C10.0085 23.8375 10.156 23.7383 10.2799 23.6133L21.3333 12.5467V22.6667C21.3333 23.0203 21.4737 23.3594 21.7238 23.6095C21.9738 23.8595 22.313 24 22.6666 24C23.0202 24 23.3593 23.8595 23.6094 23.6095C23.8594 23.3594 23.9999 23.0203 23.9999 22.6667V9.33333C23.9978 9.1591 23.9616 8.98696 23.8933 8.82667Z"
                          fill="#FFB11A"
                        />
                      </svg>
                    </div>
                    <div>
                      <h4 className="text-2xl text-white font-medium">
                        Analytics and Insights
                      </h4>
                      <p className="text-lg text-gray-600 tracking-tight">
                        Gain valuable insights into your videos.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-1/2 px-4">
                <div className="relative max-w-md xl:max-w-lg mx-auto xl:mx-0">
                  <img
                    className="absolute top-0 right-0 -mt-40 md:-mr-12 animate-spinStar"
                    src="homepage/bromo-assets/features/blink-star.png"
                    alt=""
                  />
                  <img
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                    src="homepage/bromo-assets/features/elipse-color.png"
                    alt=""
                  />
                  <img
                    className="block mx-auto relative"
                    src="homepage/bromo-assets/features/phone-image.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-12 sm:py-24 bg-body overflow-hidden">
          <img
            className="absolute top-0 left-0 w-full"
            src="homepage/bromo-assets/features/triangle-top-lines.png"
            alt=""
          />
          <div className="relative container mx-auto px-4">
            <div className="max-w-sm xs:max-w-4xl mb-14">
              <span className="inline-flex items-center px-3.5 h-7 mb-6 text-xs font-medium text-white leading-6 bg-gradient-tag rounded-full">
                COMING SOON
              </span>
              <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">
                AI assistant that actually edits the video for you.
              </h2>
              <p className="text-xl text-gray-400">
                We powered by state-of-the-art AI Models.
              </p>
            </div>
            <div className="flex flex-wrap -mx-4">
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src="homepage/bromo-assets/features/image-banner-box-1.png"
                    alt=""
                  />
                  <div className="relative">
                    <span className="block text-6xl font-medium text-white mb-6">
                      01
                    </span>
                    <span className="block text-2xl font-medium text-white">
                      Update Videos via API in realtime
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src="homepage/bromo-assets/features/image-banner-box-2.png"
                    alt=""
                  />
                  <div className="relative">
                    <span className="block text-6xl font-medium text-white mb-6">
                      02
                    </span>
                    <span className="block text-2xl font-medium text-white">
                      Integrate with your product
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src="homepage/bromo-assets/features/image-banner-box-3.png"
                    alt=""
                  />
                  <div className="relative">
                    <span className="block text-6xl font-medium text-white mb-6">
                      03
                    </span>
                    <span className="block text-2xl font-medium text-white">
                      Dedicated Pipelines development
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4 mb-8">
                <div className="relative h-48 xl:h-44 pt-4 md:pt-6 px-9 pb-7 rounded-3xl overflow-hidden">
                  <img
                    className="absolute top-0 left-0 w-full h-full object-cover"
                    src="homepage/bromo-assets/features/image-banner-box-4.png"
                    alt=""
                  />
                  <div className="relative">
                    <span className="block text-6xl font-medium text-white mb-6">
                      04
                    </span>
                    <span className="block text-2xl font-medium text-white">
                      Seamless Video Production
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="absolute top-0 right-0 -mt-80 -mr-80 w-148 h-148 bg-gradient-to-t from-violet-700 to-darkBlue-900 rounded-full filter blur-4xl" />
            <div className="relative max-w-8xl mx-auto py-16 px-8 md:px-16 rounded-3xl shadow-box-violet overflow-hidden bg-white bg-opacity-10">
              <img
                className="hidden md:block absolute top-0 right-0 mt-24 lg:mt-14 mr-6 lg:mr-40 animate-spinStar"
                src="homepage/bromo-assets/newsletter/blink-star.png"
                alt=""
              />
              <div className="max-w-lg mb-16">
                <h2 className="font-heading text-5xl sm:text-7xl font-medium text-white tracking-tight mb-6">
                  Stay up to Date
                </h2>
                <p className="text-lg text-gray-400">
                  Get Monthly updates to the product, with the actionable
                  insights.
                </p>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/2 px-4 mb-14 lg:mb-0">
                  <div className="flex items-center">
                    <img
                      className="block w-18 h-18"
                      src="homepage/bromo-assets/newsletter/avatar.png"
                      alt=""
                    />
                    <div className="ml-8">
                      <span className="block text-5xl font-medium text-white leading-none">
                        Join our community
                      </span>
                      <span className="block text-lg text-gray-500 leading-none">
                        and get realtime support.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/2 px-4">
                  <div className="max-w-md">
                    <span className="block mb-2 text-sm font-medium text-white leading-snug">
                      Email
                    </span>
                    <div className="sm:flex mb-5">
                      <input
                        className="block w-full mb-4 sm:mb-0 sm:mr-3 py-2 px-3 h-11 bg-gray-800 border border-gray-700 focus:border-violet-500 rounded-lg text-sm placeholder-gray-500 text-white outline-none"
                        type="email"
                        placeholder="support@bromo.io"
                      />
                      <button
                        className="sm:flex-shrink-0 flex w-full sm:w-auto h-11 py-2 px-5 items-center justify-center text-sm font-medium uppercase text-black hover:text-white bg-violet-500 hover:bg-violet-600 transition duration-200 rounded-full"
                        type="submit"
                      >
                        <span className="mr-2">Submit</span>
                        <svg
                          width={25}
                          height={24}
                          viewBox="0 0 25 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.4199 6.62C18.3185 6.37565 18.1243 6.18147 17.8799 6.08C17.7597 6.02876 17.6306 6.00158 17.4999 6H7.49994C7.23472 6 6.98037 6.10536 6.79283 6.29289C6.6053 6.48043 6.49994 6.73478 6.49994 7C6.49994 7.26522 6.6053 7.51957 6.79283 7.70711C6.98037 7.89464 7.23472 8 7.49994 8H15.0899L6.78994 16.29C6.69621 16.383 6.62182 16.4936 6.57105 16.6154C6.52028 16.7373 6.49414 16.868 6.49414 17C6.49414 17.132 6.52028 17.2627 6.57105 17.3846C6.62182 17.5064 6.69621 17.617 6.78994 17.71C6.8829 17.8037 6.9935 17.8781 7.11536 17.9289C7.23722 17.9797 7.36793 18.0058 7.49994 18.0058C7.63195 18.0058 7.76266 17.9797 7.88452 17.9289C8.00638 17.8781 8.11698 17.8037 8.20994 17.71L16.4999 9.41V17C16.4999 17.2652 16.6053 17.5196 16.7928 17.7071C16.9804 17.8946 17.2347 18 17.4999 18C17.7652 18 18.0195 17.8946 18.207 17.7071C18.3946 17.5196 18.4999 17.2652 18.4999 17V7C18.4984 6.86932 18.4712 6.74022 18.4199 6.62Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                    <label className="custom-checkbox flex items-center relative h-8 cursor-pointer">
                      <p className="inline-block ml-6 text-sm text-gray-400">
                        <span>I agree with </span>
                        <a
                          className="inline-block text-sky-900 hover:text-violet-500"
                          href="/privacy"
                        >
                          Privacy Policy
                        </a>
                        <span> and </span>
                        <a
                          className="inline-block text-sky-900 hover:text-violet-500"
                          href="/terms"
                        >
                          Terms of Conditions
                        </a>
                      </p>
                      <input
                        className="absolute top-0 left-0 transform translate-y-1/2 w-4 h-4 opacity-0"
                        type="checkbox"
                        defaultChecked={false}
                      />
                      <span className="custom-check absolute top-0 left-0 transform translate-y-1/2 flex h-4 w-4 items-center justify-center bg-violet-400 border border-violet-700 rounded">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.4733 4.80667C12.4114 4.74418 12.3376 4.69458 12.2564 4.66074C12.1752 4.62689 12.088 4.60947 12 4.60947C11.912 4.60947 11.8249 4.62689 11.7436 4.66074C11.6624 4.69458 11.5886 4.74418 11.5267 4.80667L6.56001 9.78L4.47334 7.68667C4.40899 7.62451 4.33303 7.57563 4.2498 7.54283C4.16656 7.51003 4.07768 7.49394 3.98822 7.49549C3.89877 7.49703 3.8105 7.51619 3.72844 7.55185C3.64639 7.58751 3.57217 7.63898 3.51001 7.70333C3.44785 7.76768 3.39897 7.84364 3.36617 7.92688C3.33337 8.01011 3.31728 8.099 3.31883 8.18845C3.32038 8.2779 3.33953 8.36618 3.37519 8.44823C3.41085 8.53028 3.46233 8.60451 3.52667 8.66667L6.08667 11.2267C6.14865 11.2892 6.22238 11.3387 6.30362 11.3726C6.38486 11.4064 6.472 11.4239 6.56001 11.4239C6.64802 11.4239 6.73515 11.4064 6.81639 11.3726C6.89763 11.3387 6.97137 11.2892 7.03334 11.2267L12.4733 5.78667C12.541 5.72424 12.595 5.64847 12.632 5.56414C12.6689 5.4798 12.688 5.38873 12.688 5.29667C12.688 5.2046 12.6689 5.11353 12.632 5.02919C12.595 4.94486 12.541 4.86909 12.4733 4.80667Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="relative py-12 sm:pt-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="absolute top-0 left-0 -mt-40 -ml-52 w-186 h-186 bg-gradient-to-t from-violet-700 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
            <div className="relative max-w-2xl mx-auto mb-14 text-center">
              <span className="inline-flex items-center px-3.5 h-7 mb-4 text-xs font-medium text-white leading-6 bg-gradient-tag rounded-full">
                PRICING PLAN
              </span>
              <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl xl:text-7xl font-medium text-white tracking-tight mb-4">
                Choose Your Plan
              </h2>
              <p className="max-w-md mx-auto text-lg leading-snug tracking-tight text-gray-500">
                Nunc et tellus non erat accumsan aliquam eget non mi. Integer
                fringilla pellentesque finibus.
              </p>
            </div>
            <div className="relative max-w-md lg:max-w-8xl mx-auto">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                  <div className="rounded-3xl overflow-hidden">
                    <img
                      className="block w-full h-44 object-cover"
                      src="homepage/bromo-assets/pricing/pricing-top-1.png"
                      alt=""
                    />
                    <div className="bg-white p-10">
                      <span className="block mb-10 text-4xl font-semibold text-gray-900">
                        $50
                      </span>
                      <span className="block mb-2 text-2xl font-medium text-gray-900">
                        Beginner
                      </span>
                      <p className="max-w-xs text-sm text-gray-600 mb-8">
                        Simply dummy text of the printing and typesetting
                        industry.
                      </p>
                      <div>
                        <span className="block mb-4 text-lg font-medium text-gray-900 tracking-tight">
                          What’s included:
                        </span>
                        <ul className="mb-8">
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Unlimited Upload Files
                            </span>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Advanced Statistic With
                            </span>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-red-600">
                              <svg
                                width={12}
                                height={12}
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3L3 9"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3 3L9 9"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Powered by state-of-the-art AI
                            </span>
                          </li>
                          <li className="flex items-center">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-red-600">
                              <svg
                                width={12}
                                height={12}
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3L3 9"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3 3L9 9"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Bromo will adapts to each user
                            </span>
                          </li>
                        </ul>
                        <a
                          className="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-black transition duration-200 rounded-full"
                          href="/todo"
                        >
                          <span className="mr-2">CHOOSE PLAN</span>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                  <div className="rounded-3xl overflow-hidden">
                    <img
                      className="block w-full h-44 object-cover"
                      src="homepage/bromo-assets/pricing/pricing-top-2.png"
                      alt=""
                    />
                    <div className="bg-white p-10">
                      <span className="block mb-10 text-4xl font-semibold text-gray-900">
                        $100
                      </span>
                      <span className="block mb-2 text-2xl font-medium text-gray-900">
                        Intermediate
                      </span>
                      <p className="max-w-xs text-sm text-gray-600 mb-8">
                        Simply dummy text of the printing and typesetting
                        industry.
                      </p>
                      <div>
                        <span className="block mb-4 text-lg font-medium text-gray-900 tracking-tight">
                          What’s included:
                        </span>
                        <ul className="mb-8">
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Unlimited Upload Files
                            </span>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Advanced Statistic With
                            </span>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Powered by state-of-the-art AI
                            </span>
                          </li>
                          <li className="flex items-center">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-red-600">
                              <svg
                                width={12}
                                height={12}
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 3L3 9"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M3 3L9 9"
                                  stroke="black"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Bromo will adapts to each user
                            </span>
                          </li>
                        </ul>
                        <a
                          className="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-black transition duration-200 rounded-full"
                          href="/todo"
                        >
                          <span className="mr-2">CHOOSE PLAN</span>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4">
                  <div className="rounded-3xl overflow-hidden">
                    <img
                      className="block w-full h-44 object-cover"
                      src="homepage/bromo-assets/pricing/pricing-top-3.png"
                      alt=""
                    />
                    <div className="bg-white p-10">
                      <span className="block mb-10 text-4xl font-semibold text-gray-900">
                        $150
                      </span>
                      <span className="block mb-2 text-2xl font-medium text-gray-900">
                        Advanced
                      </span>
                      <p className="max-w-xs text-sm text-gray-600 mb-8">
                        Simply dummy text of the printing and typesetting
                        industry.
                      </p>
                      <div>
                        <span className="block mb-4 text-lg font-medium text-gray-900 tracking-tight">
                          What’s included:
                        </span>
                        <ul className="mb-8">
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Unlimited Upload Files
                            </span>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Advanced Statistic With
                            </span>
                          </li>
                          <li className="flex items-center mb-4">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Powered by state-of-the-art AI
                            </span>
                          </li>
                          <li className="flex items-center">
                            <div className="flex mr-2 items-center justify-center w-5 h-5 rounded-full bg-green-600">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.4732 4.80667C12.4112 4.74418 12.3375 4.69458 12.2563 4.66074C12.175 4.62689 12.0879 4.60947 11.9999 4.60947C11.9119 4.60947 11.8247 4.62689 11.7435 4.66074C11.6623 4.69458 11.5885 4.74418 11.5266 4.80667L6.55989 9.78L4.47322 7.68667C4.40887 7.62451 4.33291 7.57563 4.24967 7.54283C4.16644 7.51003 4.07755 7.49394 3.9881 7.49549C3.89865 7.49703 3.81037 7.51619 3.72832 7.55185C3.64627 7.58751 3.57204 7.63898 3.50989 7.70333C3.44773 7.76768 3.39885 7.84364 3.36605 7.92688C3.33324 8.01011 3.31716 8.099 3.31871 8.18845C3.32025 8.2779 3.3394 8.36618 3.37507 8.44823C3.41073 8.53028 3.4622 8.60451 3.52655 8.66667L6.08655 11.2267C6.14853 11.2892 6.22226 11.3387 6.3035 11.3726C6.38474 11.4064 6.47188 11.4239 6.55989 11.4239C6.64789 11.4239 6.73503 11.4064 6.81627 11.3726C6.89751 11.3387 6.97124 11.2892 7.03322 11.2267L12.4732 5.78667C12.5409 5.72424 12.5949 5.64847 12.6318 5.56414C12.6688 5.4798 12.6878 5.38873 12.6878 5.29667C12.6878 5.2046 12.6688 5.11353 12.6318 5.02919C12.5949 4.94486 12.5409 4.86909 12.4732 4.80667Z"
                                  fill="black"
                                />
                              </svg>
                            </div>
                            <span className="text-base text-gray-900 tracking-tight">
                              Bromo will adapts to each user
                            </span>
                          </li>
                        </ul>
                        <a
                          className="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-black hover:text-white bg-violet-500 hover:bg-black transition duration-200 rounded-full"
                          href="/todo"
                        >
                          <span className="mr-2">CHOOSE PLAN</span>
                          <svg
                            width={24}
                            height={24}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                              fill="currentColor"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="relative py-12 md:py-24 lg:pb-32 bg-body overflow-hidden">
          <img
            className="absolute bottom-0 left-0 w-full"
            src="homepage/bromo-assets/testimonials/triangle-line-bottom.png"
            alt=""
          />
          <div className="relative container mx-auto px-4">
            <div className="max-w-3xl mx-auto mb-18 text-center">
              <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl lg:text-7xl font-medium text-white tracking-tight mb-8">
                Our Customer Says
              </h2>
              <p className="max-w-xl mx-auto text-xl text-gray-400 leading-relaxed tracking-tight">
                Bromo is powered by state-of-the-art artificial intelligence
              </p>
            </div>
            <div className="max-w-lg lg:max-w-8xl mx-auto">
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                  <div className="p-7 mb-10 bg-white rounded-3xl">
                    <div className="flex mb-5 items-center">
                      <img
                        className="w-14 h-14 rounded-full"
                        src="homepage/bromo-assets/testimonials/avatar-1.png"
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="block text-xl font-medium text-black leading-6">
                          Bambang Sucat
                        </span>
                        <span className="text-sm text-gray-600">
                          CEO of Coca Soft
                        </span>
                      </div>
                    </div>
                    <div className="flex mb-5 items-center">
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                    </div>
                    <p className="text-lg text-black tracking-tight leading-snug">
                      It was a pleasure working with the Saturn. They understood
                      the brief correctly and delivered great designs exceeding
                      the expectations.
                    </p>
                  </div>
                  <div className="p-7 bg-white rounded-3xl">
                    <div className="flex mb-5 items-center">
                      <img
                        className="w-14 h-14 rounded-full"
                        src="homepage/bromo-assets/testimonials/avatar-2.png"
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="block text-xl font-medium text-black leading-6">
                          Gatot Jonatan
                        </span>
                        <span className="text-sm text-gray-600">
                          CEO of Coca Soft
                        </span>
                      </div>
                    </div>
                    <div className="flex mb-5 items-center">
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                    </div>
                    <img
                      className="block w-full mb-5"
                      src="homepage/bromo-assets/testimonials/photo-small.png"
                      alt=""
                    />
                    <p className="text-lg text-black tracking-tight leading-snug">
                      It was a pleasure working with the Saturn. They understood
                      the brief correctly and delivered great designs exceeding
                      the expectations.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4 mb-8 lg:mb-0">
                  <div className="p-7 mb-10 bg-white rounded-3xl">
                    <div className="flex mb-5 items-center">
                      <img
                        className="w-14 h-14 rounded-full"
                        src="homepage/bromo-assets/testimonials/avatar-3.png"
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="block text-xl font-medium text-black leading-6">
                          Beby Aduhai
                        </span>
                        <span className="text-sm text-gray-600">
                          CEO of Sans Design
                        </span>
                      </div>
                    </div>
                    <div className="flex mb-5 items-center">
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                    </div>
                    <img
                      className="block w-full mb-5"
                      src="homepage/bromo-assets/testimonials/image-small-1.png"
                      alt=""
                    />
                    <p className="text-lg text-black tracking-tight leading-snug">
                      It was a pleasure working with the Saturn. They understood
                      the brief correctly and delivered great designs exceeding
                      the expectations.
                    </p>
                  </div>
                  <div className="p-7 bg-white rounded-3xl">
                    <div className="flex mb-5 items-center">
                      <img
                        className="w-14 h-14 rounded-full"
                        src="homepage/bromo-assets/testimonials/avatar-4.png"
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="block text-xl font-medium text-black leading-6">
                          Debora Wulan
                        </span>
                        <span className="text-sm text-gray-600">
                          CEO of Coca Soft
                        </span>
                      </div>
                    </div>
                    <div className="flex mb-5 items-center">
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                    </div>
                    <p className="text-lg text-black tracking-tight leading-snug">
                      It was a pleasure working with the Saturn. They understood
                      the brief correctly and delivered great designs exceeding
                      the expectations.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4">
                  <div className="p-7 mb-10 bg-white rounded-3xl">
                    <div className="flex mb-5 items-center">
                      <img
                        className="w-14 h-14 rounded-full"
                        src="homepage/bromo-assets/testimonials/avatar-2.png"
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="block text-xl font-medium text-black leading-6">
                          Bambang Sucat
                        </span>
                        <span className="text-sm text-gray-600">
                          CEO of Coca Soft
                        </span>
                      </div>
                    </div>
                    <div className="flex mb-5 items-center">
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                    </div>
                    <p className="text-lg text-black tracking-tight leading-snug">
                      It was a pleasure working with the Saturn. They understood
                      the brief correctly and delivered great designs exceeding
                      the expectations.
                    </p>
                  </div>
                  <div className="p-7 bg-white rounded-3xl">
                    <div className="flex mb-5 items-center">
                      <img
                        className="w-14 h-14 rounded-full"
                        src="homepage/bromo-assets/testimonials/avatar-1.png"
                        alt=""
                      />
                      <div className="ml-5">
                        <span className="block text-xl font-medium text-black leading-6">
                          Gatot Jonatan
                        </span>
                        <span className="text-sm text-gray-600">
                          CEO of Coca Soft
                        </span>
                      </div>
                    </div>
                    <div className="flex mb-5 items-center">
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        className="mr-2"
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.227 0.796478L13.2574 5.09148C13.4564 5.51312 13.8405 5.8055 14.2859 5.87316L18.8282 6.56543C19.9504 6.73699 20.397 8.17711 19.5848 9.00228L16.3002 12.344C15.9774 12.6727 15.8305 13.1451 15.9068 13.609L16.682 18.3268C16.8729 19.4939 15.6997 20.3843 14.6967 19.8322L10.6369 17.6032C10.2389 17.3845 9.76225 17.3845 9.3631 17.6032L5.30334 19.8322C4.30026 20.3843 3.1271 19.4939 3.31916 18.3268L4.09316 13.609C4.16952 13.1451 4.02259 12.6727 3.6998 12.344L0.4152 9.00228C-0.396983 8.17711 0.0496019 6.73699 1.17185 6.56543L5.71406 5.87316C6.15949 5.8055 6.54475 5.51312 6.74375 5.09148L8.77305 0.796478C9.27517 -0.265493 10.7248 -0.265493 11.227 0.796478Z"
                          fill="#FFD33C"
                        />
                      </svg>
                    </div>
                    <img
                      className="block w-full mb-5"
                      src="homepage/bromo-assets/testimonials/image-small-2.png"
                      alt=""
                    />
                    <p className="text-lg text-black tracking-tight leading-snug">
                      It was a pleasure working with the Saturn. They understood
                      the brief correctly and delivered great designs exceeding
                      the expectations.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="relative pb-12 pt-20 md:pb-20 lg:pb-24 lg:pt-32 overflow-hidden">
          <div className="container mx-auto px-4">
            <div className="relative max-w-8xl mx-auto">
              <img
                className="absolute top-0 left-0 -mt-20 ml-24 z-10 w-40 lg:w-auto"
                src="homepage/bromo-assets/cta/robo-bubble.png"
                alt=""
              />
              <div className="relative px-8 py-20 rounded-5xl overflow-hidden">
                <img
                  className="absolute top-0 left-0 w-full h-full object-cover"
                  src="homepage/bromo-assets/cta/bg-gradient-banner.png"
                  alt=""
                />
                <div className="relative max-w-5xl mx-auto text-center">
                  <h3 className="font-heading text-2xl lg:text-4xl text-black font-medium leading-tight mb-8">
                    Ready to collaborate?
                  </h3>
                  <h2 className="font-heading text-4xl xs:text-5xl lg:text-6xl text-black font-medium tracking-tight mb-8">
                    Sign up &amp; transform your customer interactions
                  </h2>
                  <a
                    className="group inline-flex w-full sm:w-auto h-14 mb-8 px-7 items-center justify-center text-base font-medium text-black hover:text-violet-800 bg-violet-500 hover:bg-white transition duration-200 rounded-full"
                    href="/login"
                  >
                    <span className="mr-2">START FREE TRIAL</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                  <p className="text-base text-black tracking-tight">
                    <span>Join </span>
                    <span className="font-medium">50,000+ </span>
                    <span>other creators across our communities.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="absolute bottom-0 right-0 mr-20 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
            <div className="max-w-md mx-auto lg:max-w-none relative">
              <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">
                Bromo Services
              </h2>
              <p className="text-xl text-gray-500 tracking-tight mb-16">
                Mi turpis turpis in justo pellentesque id nibh praesent.
              </p>
              <div className="flex flex-wrap -mx-4">
                <div className="w-full lg:w-1/3 px-4 mb-20 lg:mb-0">
                  <div>
                    <img
                      className="block mb-6"
                      src="homepage/bromo-assets/services/circle-icon1.png"
                      alt=""
                    />
                    <h4 className="font-heading text-3xl font-medium text-white">
                      Intelligent Conersations
                    </h4>
                    <div className="h-1 bg-white bg-opacity-10 my-6 rounded-md" />
                    <p className="max-w-sm text-xl tracking-tight leading-relaxed text-violet-50">
                      From answering questions to providing recommendations, our
                      AI assistant will engage your visitors effortlessly.
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4 mb-20 lg:mb-0">
                  <div>
                    <img
                      className="block mb-6"
                      src="homepage/bromo-assets/services/circle-icon3.png"
                      alt=""
                    />
                    <h4 className="font-heading text-3xl font-medium text-white">
                      Intelligent Conersations
                    </h4>
                    <div className="h-1 bg-white bg-opacity-10 my-6 rounded-md" />
                    <p className="max-w-xs text-xl tracking-tight leading-relaxed text-violet-50">
                      By understanding your visitors' needs, our chat assistant
                      creates a unique experience
                    </p>
                  </div>
                </div>
                <div className="w-full lg:w-1/3 px-4">
                  <div>
                    <img
                      className="block mb-6"
                      src="homepage/bromo-assets/services/circle-icon2.png"
                      alt=""
                    />
                    <h4 className="font-heading text-3xl font-medium text-white">
                      Intelligent Conersations
                    </h4>
                    <div className="h-1 bg-white bg-opacity-10 my-6 rounded-md" />
                    <p className="max-w-xs text-xl tracking-tight leading-relaxed text-violet-50">
                      Say goodbye to static web pages and hello to an
                      interactive, conversational platform
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="absolute top-0 right-0 w-186 h-186 bg-gradient-to-t from-violet-900 to-darkBlue-900 rounded-full filter blur-4xl" />
            <div className="relative max-w-md md:max-w-8xl mx-auto">
              <div className="text-center mb-20 md:mb-40">
                <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-6">
                  How Does Bromo Work
                </h2>
                <p className="text-xl text-gray-400">
                  Mi turpis turpis in justo pellentesque id nibh praesent.
                </p>
              </div>
              <div className="flex flex-wrap -mx-4 -mb-24">
                <div className="w-full md:w-1/2 px-4 mb-24">
                  <div className="max-w-md xl:max-w-lg">
                    <div className="mb-8">
                      <h3 className="font-heading text-4xl xl:text-5xl font-semibold text-white">
                        01. Upload
                      </h3>
                      <div className="my-6 h-1 w-full bg-white bg-opacity-20" />
                      <p className="text-xl text-gray-100 tracking-tight">
                        Our user-friendly interface allows for quick setup, and
                        our dedicated support team is ready to assist you every
                        step of the way.
                      </p>
                    </div>
                    <div className="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                      <img
                        className="absolute top-0 left-0 w-full h-full"
                        src="homepage/bromo-assets/how-it-works/bg-square-gradient.png"
                        alt=""
                      />
                      <img
                        className="relative rounded-tl-3xl object-cover"
                        src="homepage/bromo-assets/how-it-works/image-square-sm-2.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4 mb-24">
                  <div className="max-w-md xl:max-w-lg md:ml-auto">
                    <div className="mb-8">
                      <h3 className="font-heading text-4xl xl:text-5xl font-semibold text-white">
                        03. Select &amp; Send
                      </h3>
                      <div className="my-6 h-1 w-full bg-white bg-opacity-20" />
                      <p className="text-xl text-gray-100 tracking-tight">
                        Gain valuable insights into your users' preferences,
                        behavior, and frequently asked questions with Bromo
                        advanced analytics dashboard
                      </p>
                    </div>
                    <div className="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                      <img
                        className="absolute top-0 left-0 w-full h-full"
                        src="homepage/bromo-assets/how-it-works/bg-square-gradient.png"
                        alt=""
                      />
                      <img
                        className="relative rounded-tl-3xl object-cover"
                        src="homepage/bromo-assets/how-it-works/image-square-sm-3.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4 mb-24">
                  <div className="max-w-md xl:max-w-lg">
                    <div className="mb-8">
                      <h3 className="font-heading text-4xl xl:text-5xl font-semibold text-white">
                        02. Auto Generation
                      </h3>
                      <div className="my-6 h-1 w-full bg-white bg-opacity-20" />
                      <p className="text-xl text-gray-100 tracking-tight">
                        From answering questions to providing recommendations,
                        our AI assistant will engage your visitors effortlessly.
                      </p>
                    </div>
                    <div className="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                      <img
                        className="absolute top-0 left-0 w-full h-full"
                        src="homepage/bromo-assets/how-it-works/bg-square-gradient.png"
                        alt=""
                      />
                      <img
                        className="relative rounded-tl-3xl object-cover"
                        src="homepage/bromo-assets/how-it-works/image-square-sm-1.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4 mb-24">
                  <div className="max-w-md xl:max-w-lg md:ml-auto">
                    <div className="mb-8">
                      <h3 className="font-heading text-4xl xl:text-5xl font-semibold text-white">
                        04. Results
                      </h3>
                      <div className="my-6 h-1 w-full bg-white bg-opacity-20" />
                      <p className="text-xl text-gray-100 tracking-tight">
                        Gain valuable insights into your users' preferences,
                        behavior, and frequently asked questions with Bromo
                        advanced analytics dashboard
                      </p>
                    </div>
                    <div className="relative flex pt-12 lg:pt-20 pl-12 lg:pl-20 items-end justify-end rounded-3xl overflow-hidden">
                      <img
                        className="absolute top-0 left-0 w-full h-full"
                        src="homepage/bromo-assets/how-it-works/bg-square-gradient.png"
                        alt=""
                      />
                      <img
                        className="relative rounded-tl-3xl object-cover"
                        src="homepage/bromo-assets/how-it-works/image-square-sm-3.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="relative py-12 md:py-24 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <img
              className="hidden md:block absolute top-0 right-0 w-32 xl:w-auto mt-24 xl:mt-0 -mr-8 xl:-mr-24 z-10 animate-movePlant"
              src="homepage/bromo-assets/faq/shield-robot.png"
              alt=""
            />
            <img
              className="hidden md:block absolute top-0 left-0 w-40 xl:w-auto -ml-8 lg:-ml-18 -mt-20 z-10 animate-movePlanet"
              src="homepage/bromo-assets/faq/robot-ball.png"
              alt=""
            />
            <div className="absolute top-0 left-0 -ml-40 -mt-80 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
            <div className="absolute bottom-0 right-0 mb-52 w-160 h-160 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
            <div className="relative text-center mb-20">
              <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-8">
                Frequently Ask Questions
              </h2>
              <p className="text-lg text-gray-500 tracking-tight">
                Nunc et tellus non erat accumsan aliquam eget non mi.
              </p>
            </div>
            <div className="relative max-w-sm mx-auto md:max-w-none">
              <div className="flex flex-wrap -mx-4 -mb-8">
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                  <div className="relative p-9 rounded-3xl overflow-hidden">
                    <img
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src="homepage/bromo-assets/faq/bg-box1.png"
                      alt=""
                    />
                    <div className="relative">
                      <h4 className="text-xl text-white font-medium tracking-tight mb-4">
                        How to Apply
                      </h4>
                      <p className="text-white mb-4">
                        Pretium ac auctor quis urna orci feugiat. Vulputate
                        tellus velit tellus orci auctor vel nulla facilisi ut.
                      </p>
                      <a
                        className="group inline-flex h-8 px-4 items-center justify-center text-xs font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                        href="/todo"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                  <div className="relative p-9 rounded-3xl overflow-hidden">
                    <img
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src="homepage/bromo-assets/faq/bg-box2.png"
                      alt=""
                    />
                    <div className="relative">
                      <h4 className="text-xl text-white font-medium tracking-tight mb-4">
                        How to Apply
                      </h4>
                      <p className="text-white mb-4">
                        Pretium ac auctor quis urna orci feugiat. Vulputate
                        tellus velit tellus orci auctor vel nulla facilisi ut.
                      </p>
                      <a
                        className="group inline-flex h-8 px-4 items-center justify-center text-xs font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                        href="/todo"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                  <div className="relative p-9 rounded-3xl overflow-hidden">
                    <img
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src="homepage/bromo-assets/faq/bg-box3.png"
                      alt=""
                    />
                    <div className="relative">
                      <h4 className="text-xl text-white font-medium tracking-tight mb-4">
                        How to Apply
                      </h4>
                      <p className="text-white mb-4">
                        Pretium ac auctor quis urna orci feugiat. Vulputate
                        tellus velit tellus orci auctor vel nulla facilisi ut.
                      </p>
                      <a
                        className="group inline-flex h-8 px-4 items-center justify-center text-xs font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                        href="/todo"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                  <div className="relative p-9 rounded-3xl overflow-hidden">
                    <img
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src="homepage/bromo-assets/faq/bg-box4.png"
                      alt=""
                    />
                    <div className="relative">
                      <h4 className="text-xl text-white font-medium tracking-tight mb-4">
                        How to Apply
                      </h4>
                      <p className="text-white mb-4">
                        Pretium ac auctor quis urna orci feugiat. Vulputate
                        tellus velit tellus orci auctor vel nulla facilisi ut.
                      </p>
                      <a
                        className="group inline-flex h-8 px-4 items-center justify-center text-xs font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                        href="/todo"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                  <div className="relative p-9 rounded-3xl overflow-hidden">
                    <img
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src="homepage/bromo-assets/faq/bg-box1.png"
                      alt=""
                    />
                    <div className="relative">
                      <h4 className="text-xl text-white font-medium tracking-tight mb-4">
                        How to Apply
                      </h4>
                      <p className="text-white mb-4">
                        Pretium ac auctor quis urna orci feugiat. Vulputate
                        tellus velit tellus orci auctor vel nulla facilisi ut.
                      </p>
                      <a
                        className="group inline-flex h-8 px-4 items-center justify-center text-xs font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                        href="/todo"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
                  <div className="relative p-9 rounded-3xl overflow-hidden">
                    <img
                      className="absolute top-0 left-0 w-full h-full object-cover"
                      src="homepage/bromo-assets/faq/bg-box2.png"
                      alt=""
                    />
                    <div className="relative">
                      <h4 className="text-xl text-white font-medium tracking-tight mb-4">
                        How to Apply
                      </h4>
                      <p className="text-white mb-4">
                        Pretium ac auctor quis urna orci feugiat. Vulputate
                        tellus velit tellus orci auctor vel nulla facilisi ut.
                      </p>
                      <a
                        className="group inline-flex h-8 px-4 items-center justify-center text-xs font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                        href="/todo"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="relative py-12 md:py-24 lg:py-32 bg-body overflow-hidden">
          <div className="absolute top-0 right-0 -mt-52 mr-64 w-186 h-186 bg-gradient-to-t from-violet-900 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
          <img
            className="absolute top-0 right-0 h-full -mr-64 lg:-mr-0 z-10"
            src="homepage/bromo-assets/about/double-lines-lg-right.svg"
            alt=""
          />
          <img
            className="hidden md:block absolute top-1/2 right-0 transform -translate-y-1/2 z-10 h-full w-1/5 sm:w-2/5 object-contain"
            src="homepage/bromo-assets/about/robot-lg.png"
            alt=""
          />
          <div className="relative container mx-auto px-4">
            <div className="relative max-w-lg lg:max-w-2xl mx-auto md:mx-0">
              <h2 className="font-heading text-4xl xs:text-5xl sm:text-6xl md:text-7xl font-medium text-white tracking-tight mb-4">
                About Us
              </h2>
              <p className="text-lg text-gray-400 leading-relaxed tracking-tight mb-4">
                We are building the future of video editing, redesigned from scratch.
              </p>
              <p className="text-lg text-gray-400 leading-relaxed tracking-tight mb-6">
                Whether you need a video for customer support, sales, lead
                generation, or any other use case, our platform is versatile
                enough to cater to your specific requirements. Our APIs and
                integration capabilities allow for easy and deep integration across
                websites, applications, and platforms, providing a
                consistent experience for your users.
              </p>
              <a
                className="inline-flex items-center text-sm font-medium text-violet-500 hover:text-white uppercase"
                href="/todo"
              >
                <span className="mr-2">GET TO KNOW US</span>
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.92 6.62C17.8185 6.37565 17.6243 6.18147 17.38 6.08C17.2598 6.02876 17.1307 6.00158 17 6H7C6.73478 6 6.48043 6.10536 6.29289 6.29289C6.10536 6.48043 6 6.73478 6 7C6 7.26522 6.10536 7.51957 6.29289 7.70711C6.48043 7.89464 6.73478 8 7 8H14.59L6.29 16.29C6.19627 16.383 6.12188 16.4936 6.07111 16.6154C6.02034 16.7373 5.9942 16.868 5.9942 17C5.9942 17.132 6.02034 17.2627 6.07111 17.3846C6.12188 17.5064 6.19627 17.617 6.29 17.71C6.38296 17.8037 6.49356 17.8781 6.61542 17.9289C6.73728 17.9797 6.86799 18.0058 7 18.0058C7.13201 18.0058 7.26272 17.9797 7.38458 17.9289C7.50644 17.8781 7.61704 17.8037 7.71 17.71L16 9.41V17C16 17.2652 16.1054 17.5196 16.2929 17.7071C16.4804 17.8946 16.7348 18 17 18C17.2652 18 17.5196 17.8946 17.7071 17.7071C17.8946 17.5196 18 17.2652 18 17V7C17.9984 6.86932 17.9712 6.74022 17.92 6.62Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
              {/* <div className="mt-14">
                <div className="relative flex flex-col sm:flex-row">
                  <div className="pb-8 sm:pb-0 sm:pr-8">
                    <span className="block text-5xl lg:text-7xl text-white font-medium">
                      300+
                    </span>
                    <span className="block text-gray-500 tracking-tight">
                      Videos
                    </span>
                  </div>
                  <div className="relative py-8 sm:py-0 sm:px-8">
                    <span className="block text-5xl lg:text-7xl text-white font-medium">
                      100+
                    </span>
                    <span className="block text-gray-500 tracking-tight">
                      WEB TEMPLATE
                    </span>
                    <div className="hidden sm:block absolute top-0 left-0 h-full w-px bg-white bg-opacity-20" />
                    <div className="hidden sm:block absolute top-0 right-0 h-full w-px bg-white bg-opacity-20" />
                    <div className="sm:hidden absolute top-0 left-0 w-full h-px bg-white bg-opacity-20" />
                    <div className="sm:hidden absolute bottom-0 left-0 w-full h-px bg-white bg-opacity-20" />
                  </div>
                  <div className="pt-8 sm:pt-0 sm:pl-8">
                    <span className="block text-5xl lg:text-7xl text-white font-medium">
                      215
                    </span>
                    <span className="block text-gray-500 tracking-tight">
                      MOBILE TEMPLATE
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
        <section className="relative pb-12 md:py-24 lg:py-32 bg-body overflow-hidden">
          <div className="relative container mx-auto px-4">
            <div className="flex flex-wrap -mx-4">
              <div className="relative w-full lg:w-1/2 px-4 mb-8 lg:mb-0">
                <div className="absolute top-0 left-0 -ml-64 w-160 h-160 bg-gradient-to-t from-purple-700 to-darkBlue-900 rounded-full filter blur-4xl" />
                <img
                  className="absolute top-0 right-0 md:-mt-20 mr-4 md:mr-32 w-52 md:w-auto"
                  src="homepage/bromo-assets/team/planet.png"
                  alt=""
                />
                <img
                  className="absolute bottom-0 right-0 mr-40 animate-spinStar"
                  src="homepage/bromo-assets/team/blink-star.png"
                  alt=""
                />
                <div className="relative pt-52 pb-40 max-w-lg mx-auto lg:mx-0">
                  <h2 className="max-w-md font-heading text-4xl xs:text-5xl sm:text-6xl font-medium text-white tracking-tight mb-8">
                    Meet our amazing Team
                  </h2>
                  <p className="text-xl text-gray-500 tracking-tight mb-8">
                    Come join the best.
                  </p>
                  <a
                    className="group inline-flex w-full md:w-auto h-14 px-7 items-center justify-center text-base font-medium text-white hover:text-violet-500 bg-transparent hover:bg-white border-2 border-white transition duration-200 rounded-full"
                    href="https://calendly.com/bhanuc/15min"
                  >
                    <span className="mr-2">Join Us</span>
                    <svg
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.9199 6.62C17.8185 6.37565 17.6243 6.18147 17.3799 6.08C17.2597 6.02876 17.1306 6.00158 16.9999 6H6.99994C6.73472 6 6.48037 6.10536 6.29283 6.29289C6.1053 6.48043 5.99994 6.73478 5.99994 7C5.99994 7.26522 6.1053 7.51957 6.29283 7.70711C6.48037 7.89464 6.73472 8 6.99994 8H14.5899L6.28994 16.29C6.19621 16.383 6.12182 16.4936 6.07105 16.6154C6.02028 16.7373 5.99414 16.868 5.99414 17C5.99414 17.132 6.02028 17.2627 6.07105 17.3846C6.12182 17.5064 6.19621 17.617 6.28994 17.71C6.3829 17.8037 6.4935 17.8781 6.61536 17.9289C6.73722 17.9797 6.86793 18.0058 6.99994 18.0058C7.13195 18.0058 7.26266 17.9797 7.38452 17.9289C7.50638 17.8781 7.61698 17.8037 7.70994 17.71L15.9999 9.41V17C15.9999 17.2652 16.1053 17.5196 16.2928 17.7071C16.4804 17.8946 16.7347 18 16.9999 18C17.2652 18 17.5195 17.8946 17.707 17.7071C17.8946 17.5196 17.9999 17.2652 17.9999 17V7C17.9984 6.86932 17.9712 6.74022 17.9199 6.62Z"
                        fill="currentColor"
                      />
                    </svg>
                  </a>
                </div>
              </div>
              <div className="relative w-full lg:w-1/2 px-4">
                <div className="max-w-lg md:max-w-none lg:max-w-xl 2xl:max-w-2xl mx-auto lg:mr-0">
                  <div className="flex flex-wrap -mx-4">
                    <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
                      <div className="py-10 px-11 mb-10 border-2 border-white rounded-3xl">
                        <div className="text-center">
                          <img
                            className="block mx-auto mb-6"
                            src="homepage/bromo-assets/team/avatar-2-sm.png"
                            alt=""
                          />
                          <span className="block text-2xl text-white font-medium">
                            Bhanu Pratap Chaudhary
                          </span>
                          <span className="block text-base text-violet-500 font-medium">
                            Founder
                          </span>
                        </div>
                      </div>
                      <div className="py-10 px-11 border-2 border-white rounded-3xl">
                        <div className="text-center">
                          <img
                            className="block mx-auto mb-6"
                            src="homepage/bromo-assets/team/avatar-1-sm.png"
                            alt=""
                          />
                          <span className="block text-2xl text-white font-medium">
                            Vasu Sharma
                          </span>
                          <span className="block text-base text-violet-500 font-medium">
                            Advisor- Meta Labs
                          </span>
                        </div>
                      </div>
                    </div>
                    {/* <div className="w-full md:w-1/2 px-4 md:pt-18">
                      <div className="py-10 px-11 mb-10 border-2 border-white rounded-3xl">
                        <div className="text-center">
                          <img
                            className="block mx-auto mb-6"
                            src="homepage/bromo-assets/team/avatar-1-sm.png"
                            alt=""
                          />
                          <span className="block text-2xl text-white font-medium">
                            Marvin McKinney
                          </span>
                          <span className="block text-base text-violet-500 font-medium">
                            Tech Lead
                          </span>
                        </div>
                      </div>
                      <div className="py-10 px-11 border-2 border-white rounded-3xl">
                        <div className="text-center">
                          <img
                            className="block mx-auto mb-6"
                            src="homepage/bromo-assets/team/avatar-2-sm.png"
                            alt=""
                          />
                          <span className="block text-2xl text-white font-medium">
                            Kathryn Murphy
                          </span>
                          <span className="block text-base text-violet-500 font-medium">
                            CTO
                          </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="relative py-12 sm:pt-24 bg-body overflow-hidden">
          <div className="absolute top-0 right-0 -mt-112 -mr-52 w-186 h-186 bg-gradient-to-t from-purple-600 via-darkBlue-900 to-transparent rounded-full filter blur-4xl" />
          <div className="relative container mx-auto px-4">
            <div className="flex flex-wrap items-center -mx-4 mb-16">
              <div className="relative w-full md:w-1/2 px-4 mb-20 md:mb-0">
                <img
                  className="hidden xs:block absolute top-0 right-0 -mr-112 lg:-mr-20 -mt-10 lg:mt-20 animate-spinStar"
                  src="homepage/bromo-assets/footer/blink-star.png"
                  alt=""
                />
                <div className="max-w-lg">
                  <h3 className="text-5xl font-medium text-white mb-8">
                    Gain valuable insights into your users' preferences
                  </h3>
                  <p className="max-w-xs text-white tracking-tight">
                    Bromo is here to revolutionize the way you interact with
                    your audience.
                  </p>
                </div>
              </div>
              <div className="w-full md:w-1/2 px-4">
                <div className="max-w-sm md:ml-auto">
                  <a className="inline-block mb-16" href="/">
                    <img
                      className="block"
                      src="homepage/images/logo-white.svg"
                      alt=""
                    />
                  </a>
                  {/* <h3 className="text-3xl font-medium text-white mb-3">
                    Subscribe to our newsletter
                  </h3>
                  <div>
                    <span className="block mb-1 text-white text-sm font-medium">
                      Email
                    </span>
                    <div className="inline-flex items-center h-12 py-2 pl-3 pr-2 rounded-full bg-gray-800">
                      <span>
                        <svg
                          width={24}
                          height={25}
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19 4.5H5C4.20435 4.5 3.44129 4.81607 2.87868 5.37868C2.31607 5.94129 2 6.70435 2 7.5V17.5C2 18.2956 2.31607 19.0587 2.87868 19.6213C3.44129 20.1839 4.20435 20.5 5 20.5H19C19.7956 20.5 20.5587 20.1839 21.1213 19.6213C21.6839 19.0587 22 18.2956 22 17.5V7.5C22 6.70435 21.6839 5.94129 21.1213 5.37868C20.5587 4.81607 19.7956 4.5 19 4.5ZM18.59 6.5L12.71 12.38C12.617 12.4737 12.5064 12.5481 12.3846 12.5989C12.2627 12.6497 12.132 12.6758 12 12.6758C11.868 12.6758 11.7373 12.6497 11.6154 12.5989C11.4936 12.5481 11.383 12.4737 11.29 12.38L5.41 6.5H18.59ZM20 17.5C20 17.7652 19.8946 18.0196 19.7071 18.2071C19.5196 18.3946 19.2652 18.5 19 18.5H5C4.73478 18.5 4.48043 18.3946 4.29289 18.2071C4.10536 18.0196 4 17.7652 4 17.5V7.91L9.88 13.79C10.4425 14.3518 11.205 14.6674 12 14.6674C12.795 14.6674 13.5575 14.3518 14.12 13.79L20 7.91V17.5Z"
                            fill="#80818C"
                          />
                        </svg>
                      </span>
                      <input
                        className="px-2 text-sm placeholder-gray-600 text-gray-200 bg-transparent outline-none"
                        type="email"
                        placeholder="support@bromo.io"
                      />
                      <button
                        className="flex items-center justify-center w-9 h-9 rounded-full text-black hover:text-violet-700 bg-violet-500 hover:bg-white"
                        type="submit"
                      >
                        <svg
                          width={20}
                          height={21}
                          viewBox="0 0 20 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.4333 11.0167C17.3937 10.9144 17.3342 10.8209 17.2583 10.7417L14.7583 8.24167C14.6014 8.08475 14.3886 7.99659 14.1667 7.99659C13.9447 7.99659 13.7319 8.08475 13.575 8.24167C13.4181 8.39859 13.3299 8.61142 13.3299 8.83333C13.3299 9.05525 13.4181 9.26808 13.575 9.425L14.6583 10.5H6.66666C6.44564 10.5 6.23368 10.4122 6.0774 10.2559C5.92112 10.0996 5.83332 9.88768 5.83332 9.66667V6.33333C5.83332 6.11232 5.74553 5.90036 5.58925 5.74408C5.43297 5.5878 5.221 5.5 4.99999 5.5C4.77898 5.5 4.56701 5.5878 4.41073 5.74408C4.25445 5.90036 4.16666 6.11232 4.16666 6.33333V9.66667C4.16666 10.3297 4.43005 10.9656 4.89889 11.4344C5.36773 11.9033 6.00362 12.1667 6.66666 12.1667H14.6583L13.575 13.2417C13.4969 13.3191 13.4349 13.4113 13.3926 13.5129C13.3503 13.6144 13.3285 13.7233 13.3285 13.8333C13.3285 13.9433 13.3503 14.0523 13.3926 14.1538C13.4349 14.2554 13.4969 14.3475 13.575 14.425C13.6525 14.5031 13.7446 14.5651 13.8462 14.6074C13.9477 14.6497 14.0566 14.6715 14.1667 14.6715C14.2767 14.6715 14.3856 14.6497 14.4871 14.6074C14.5887 14.5651 14.6809 14.5031 14.7583 14.425L17.2583 11.925C17.3342 11.8457 17.3937 11.7523 17.4333 11.65C17.5167 11.4471 17.5167 11.2196 17.4333 11.0167Z"
                            fill="currentColor"
                          />
                        </svg>
                      </button>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="pt-10 border-t border-gray-800">
              <div className="md:flex items-center justify-between">
                <div className="-mb-4">
                  <a
                    className="inline-block mb-4 mr-10 text-gray-500 hover:text-white tracking-tight"
                    href="/"
                  >
                    About Us
                  </a>
                  {/* <a
                    className="inline-block mb-4 mr-10 text-gray-500 hover:text-white tracking-tight"
                    href="/todo"
                  >
                    Our Work
                  </a> */}
                  {/* <a
                    className="inline-block mb-4 mr-10 text-gray-500 hover:text-white tracking-tight"
                    href="/todo"
                  >
                    Discover
                  </a> */}
                  <a
                    className="inline-block mb-4 text-gray-500 hover:text-white tracking-tight"
                    href="https://calendly.com/bhanuc/15min"
                  >
                    Contact Us
                  </a>
                </div>
                <div>
                  <span className="text-gray-600 tracking-tight">
                    © Bromo. All rights reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}
